import HttpRequest from './HttpRequest'

class SkillNameProvider extends HttpRequest {
  findAll () {
    return this.get('/api/v1/skill-name')
  }

  create (payload) {
    return this.post('/api/v1/skill-name', payload)
  }

  update (id, payload) {
    return this.patch(`/api/v1/skill-name/${id}`, payload)
  }

  remove (id) {
    return this.delete(`/api/v1/skill-name/${id}`)
  }

  paginate (page = 1, limit = 10, query) {
    return this.get(`/api/v1/skill-name?page=${page}&limit=${limit}`, query)
  }
}

export default SkillNameProvider
